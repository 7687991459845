<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$l('ota.programList', '程序列表')" name="first">
      <ota-list ref="program"></ota-list>
    </el-tab-pane>
    <el-tab-pane :label="$l('ota.applyList', '申请清单')" name="second">
      <ota-apply-list ref="apply"></ota-apply-list>
    </el-tab-pane>
    <el-tab-pane v-if="adminAuth" :label="$l('ota.user', '用户')" name="three">
      <ota-user-program-list ref="userProgram"></ota-user-program-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import OtaList from "@/views/elevator/elevatorSetting/DIAoOTA/OtaList";
  import OtaApplyList from "@/views/elevator/elevatorSetting/DIAoOTA/otaApply/OtaApplyList";
  import OtaUserProgramList from "@/views/elevator/elevatorSetting/DIAoOTA/OtaUserProgramList";
  import auth from "@/util/auth";

  export default {
    components: {OtaList, OtaApplyList,OtaUserProgramList},
    data() {
      return {
        adminAuth: auth.getUserType() === 0 || auth.getUserType() === 1,
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        if (tab._props.name === "first") {
          this.$refs.program.getList(-1);
        }
        if (tab._props.name === "second") {
          this.$refs.apply.getList(-1);
        }
        if (tab._props.name === "three") {
          this.$refs.userProgram.getList(-1);
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
