<template>
  <div>
    <div class="vm-separate">
      <div>
        <el-button @click="$refs.elevatorSelect.open()">
          {{ $t("elevatorUser.selectLift") }}
        </el-button>
        <elevator-select ref="elevatorSelect" :multi-selection="true" @multi-select="onMultiElevatorSelect"
          @select="onElevatorSelect"></elevator-select>
        <el-table border :data="elevatorList" height="85vh" style="margin-top: 10px;">
          <el-table-column prop="no" :label="$t('elevator.no')" align="center" width="125"></el-table-column>
          <el-table-column prop="useUnitName" show-overflow-tooltip :label="$t('elevator.useUnit')"></el-table-column>
          <el-table-column prop="name" show-overflow-tooltip
            :label="$t('elevatorNetApply.elevatorName')"></el-table-column>
          <el-table-column prop="maintComName" show-overflow-tooltip
            :label="$t('elevatorNetApply.maintComName')" width="185"></el-table-column>
          <el-table-column prop="maintEmpName" :label="$t('elevator.maintWorker')" width="170"></el-table-column>
          <el-table-column v-if="showOrgId" prop="orgName"
            :label="$t('elevator.belongCompany')"></el-table-column>
          <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="90" align="center">
            <template v-slot="scope">
              <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
                <span>{{ item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" :
                  ",") }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" width="90px" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                {{ $t("common.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="clear:both"></div>
      </div>
      <div style="margin-left: 20px;margin-top: 40px;">
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{ $t("elevator.floorComparisonTable") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="floor" label-width="115px" style="margin-top: 30px;">
            <el-form-item :label="$t('elevator.floorComparisonTable')" prop="floorDisplayId">
              <el-input v-model="floor.floorDisplayName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectFloorDisplay.open()"></el-button>
              </el-input>
              <floor-display-select ref="selectFloorDisplay" @select="onSelectFloorDisplay"></floor-display-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="floor = ''">
              {{ $t("common.reset") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" class="toolbarBtn" type="primary" size="small"
              :loading="submitLoadingFloor" @click="saveFloorTable">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{ $t("elevator.faultTemplate") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="faultTemplate" label-width="115px" style="margin-top: 30px;">
            <el-form-item :label="$t('elevator.faultTemplate')" prop="faultId">
              <el-input v-model="faultTemplate.faultTemplateName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectFaultTemplate.open()"></el-button>
              </el-input>
              <fault-select ref="selectFaultTemplate" @select="onSelectFaultTemplate"></fault-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="faultTemplate = ''">
              {{ $t("common.reset") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" type="primary" size="small"
              :loading="submitLoadingFault" @click="saveFaultTemplateTable">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{ $t("elevator.accessPlatform") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="accessPlatform" label-width="115px" style="margin-top: 20px">
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.accessPlatform')" prop="integrationKey">
                <el-select v-model="accessPlatform.integrationKey" :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%" clearable>
                  <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
                    :value="item.integrationKey"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="accessPlatform = ''">
              {{ $t("common.reset") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" type="primary" size="small"
              :loading="submitLoadingPlatform" @click="saveAccessPlatform">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{ $t("elevator.maintWorker") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="wb" label-width="140px" style="margin-top: 20px">
            <el-form-item :label="$t('elevator.maintenanced')" prop="maintComId">
              <el-input v-model="wb.maintComName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectMaintCom.open()"></el-button>
              </el-input>
              <company-select ref="selectMaintCom" @select="onSelectMaintCom"></company-select>
            </el-form-item>
            <el-form-item :label="$t('elevator.maintWorker') + '1'" prop="maintEmpId">
              <el-input v-model="wb.maintEmpName" :placeholder="$t('common.pleaseSelect')" clearable
                @clear="maintEmpClear">
                <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp()"></el-button>
              </el-input>
              <user-select ref="onSelectMaintEmp" :maint-worker-com-id="wb.maintComId"
                @select="onSelectMaintEmp"></user-select>
            </el-form-item>
            <el-form-item :label="$t('elevator.maintWorker') + '2'" prop="maintEmpId1">
              <el-input v-model="wb.maintEmpName1" :placeholder="$t('common.pleaseSelect')" clearable
                @clear="maintEmp1Clear">
                <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp1()"></el-button>
              </el-input>
              <user-select ref="onSelectMaintEmp1" :maint-worker-com-id="wb.maintComId"
                @select="onSelectMaintEmp1"></user-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="wb = ''">
              {{ $t("common.reset") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" type="primary" size="small" :loading="submitLoadingWb"
              @click="saveWb">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000;">
              {{ $t("elevator.alarmDelayTime") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="delayTime" label-width="240px" style="margin-top: 20px;">
            <el-form-item :label="$t('elevator.peopleStuckAlarmDelayTime')" prop="tiringTime">
              <el-input v-model="delayTime.tiringTime" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevator.notAvailableAlarmDelayTime')" prop="unUseTime">
              <el-input v-model="delayTime.unUseTime" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevator.finishDelayTime')" prop="finishedTime">
              <el-input v-model="delayTime.finishedTime" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="clearAlarmDelayTime">
              {{ $t("common.reset") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" type="primary" size="small"
              :loading="submitLoadingDelayTime" @click="saveDelayTime">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
        <div v-show="inspectionAuth" style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{ $t("elevator.maintenanceMan") }}
            </span>
          </el-divider>
          <el-form ref="form" :model="wb" label-width="115px" style="margin-top: 20px">
            <el-form-item :label="$t('elevator.propertyCom')" prop="propertyComId">
              <el-input v-model="wy.propertyComName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectPropertyCom.open()"></el-button>
              </el-input>
              <company-select ref="selectPropertyCom" @select="onSelectPropertyCom"></company-select>
            </el-form-item>
            <el-form-item :label="$t('elevator.safetyOfficer')" prop="safetyOfficerId">
              <el-input v-model="wy.safetyOfficerName" :placeholder="$t('common.pleaseSelect')" clearable
                @clear="propertyEmpClear">
                <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp()"></el-button>
              </el-input>
              <user-select ref="onSelectPropertyEmp" :company-id="wy.propertyComId"
                @select="onSelectPropertyEmp"></user-select>
            </el-form-item>
            <el-form-item :label="$t('elevator.safetyDirector')" prop="safetyDirectorId">
              <el-input v-model="wy.safetyDirectorName" :placeholder="$t('common.pleaseSelect')" clearable
                @clear="propertyEmp1Clear">
                <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp1()"></el-button>
              </el-input>
              <user-select ref="onSelectPropertyEmp1" :company-id="wy.propertyComId"
                @select="onSelectPropertyEmp1"></user-select>
            </el-form-item>
            <el-form-item :label="$t('elevator.safetyMainer')" prop="safetyMainerId">
              <el-input v-model="wy.safetyMainerName" :placeholder="$t('common.pleaseSelect')" clearable
                @clear="propertyEmp2Clear">
                <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp2()"></el-button>
              </el-input>
              <user-select ref="onSelectPropertyEmp2" :company-id="wy.propertyComId"
                @select="onSelectPropertyEmp2"></user-select>
            </el-form-item>
            <el-form-item :label="$l('elevator.checkTemplates', '排查模板')">
              <el-input v-model="wy.inspectionTemplateName" readonly :placeholder="$t('common.pleaseSelect')">
                <el-button slot="append" icon="el-icon-search" @click="$refs.checkTemplateSelect.open()"></el-button>
              </el-input>
              <check-template-select ref="checkTemplateSelect" @select="checkTemplateSelect"></check-template-select>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="wy.isAutoReport">{{ $l("elevator.autoGenerate", "自动生成日周月报告") }}</el-checkbox>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button style="font-size: 14px;border-radius: 8px" size="small" @click="wyClear">
              {{ $l("elevatorNetApply.reset", "重置") }}
            </el-button>
            <el-button style="font-size: 14px;border-radius: 8px" type="primary" size="small" :loading="submitLoadingWy"
              @click="saveWy">
              {{ $t("common.save") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElevatorSelect from "@/views/elevator/ElevatorSelect";
import FloorDisplaySelect from "@/views/floorDisplay/FloorDisplaySelect";
import CompanySelect from "@/views/company/CompanySelect";
import UserSelect from "@/views/user/UserSelect";
import FaultSelect from "@/views/fault/FaultSelect";
import auth from "../../../util/auth";
import CheckTemplateSelect from "@/views/dayWeekMonthManger/checkTemplates/checkTemplateSelect";

export default {
  components: { ElevatorSelect, FaultSelect, FloorDisplaySelect, CompanySelect, UserSelect, CheckTemplateSelect },
  data() {
    return {
      inspectionAuth: this.$auth(823),
      showOrgId: auth.getUsername() === "superAdmin",
      submitLoadingFloor: false,
      submitLoadingFault: false,
      submitLoadingDelayTime: false,
      submitLoadingWb: false,
      submitLoadingWy: false,
      submitLoadingPlatform: false,
      submitLoadingOrg: false,
      type: 1,
      elevatorList: [],
      elevatorFloors: [],
      floor: {
        floorDisplayId: 0,
        floorDisplayName: "",
      },
      faultTemplate: {
        faultTemplateId: 0,
        faultTemplateName: "",
      },
      delayTime: {
        tiringTime: "80",
        unUseTime: "600",
        finishedTime: "1800",
      },
      accessPlatform: {
        integrationKey: "",
      },
      wb: {
        maintComId: 0,
        maintComName: "",
        maintEmpId: 0,
        maintEmpName: "",
        maintEmpId1: 0,
        maintEmpName1: "",
      },
      wy: {
        propertyComId: 0,
        propertyComName: "",
        safetyOfficerId: 0,
        safetyOfficerName: "",
        safetyDirectorId: 0,
        safetyDirectorName: "",
        safetyMainerId: 0,
        safetyMainerName: "",
        isAutoReport: false,
        inspectionTemplateId: "",
        inspectionTemplateName: "",
      },
      integrationList: [],
    };
  },
  mounted() {
    this.getIntegrationList();
  },
  methods: {
    clearAlarmDelayTime() {
      this.delayTime = {
        tiringTime: "80",
        unUseTime: "600",
        finishedTime: "1800",
      };
    },
    wyClear() {
      this.wy = {
        propertyComId: 0,
        propertyComName: "",
        safetyOfficerId: 0,
        safetyOfficerName: "",
        safetyDirectorId: 0,
        safetyDirectorName: "",
        safetyMainerId: 0,
        safetyMainerName: "",
        isAutoReport: false,
        inspectionTemplateId: "",
        inspectionTemplateName: "",
      };
    },
    saveFloorTable() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      if (this.floor.floorDisplayId === 0) {
        this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择楼层对照"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingFloor = true;
      let params = {
        elevatorIds: elevatorIds,
        elevatorFloors: this.elevatorFloors,
        floorDisplayId: this.floor.floorDisplayId,
      };
      this.$api.save("elevators/batchUpdateFloor", params).then(res => {
        this.submitLoadingFloor = false;
        this.$emit("save-success");
        this.elevatorList = [];
        this.floor = {
          floorDisplayId: 0,
          floorDisplayName: "",
        };
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingFloor = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    saveFaultTemplateTable() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      if (this.faultTemplate.faultTemplateId === 0) {
        this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择故障模板"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingFault = true;
      let params = {
        elevatorIds: elevatorIds,
        faultTemplateId: this.faultTemplate.faultTemplateId,
      };
      this.$api.save("elevators/batchUpdateFaultTemplate", params).then(res => {
        this.submitLoadingFault = false;
        this.$emit("save-success");
        this.elevatorList = [];
        this.faultTemplate = {
          faultTemplateIdId: 0,
          faultTemplateIdName: "",
        };
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingFault = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    saveDelayTime() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      if (this.delayTime.tiringTime.trim() === "" || this.delayTime.unUseTime.trim() === "" || this.delayTime.finishedTime.trim() === "") {
        this.$message.error(this.$l("elevator.parameterEmpty", "参数不能为空"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingDelayTime = true;
      let params = {
        elevatorIds: elevatorIds,
        tiringTime: this.delayTime.tiringTime,
        unUseTime: this.delayTime.unUseTime,
        finishedTime: this.delayTime.finishedTime,
      };
      this.$api.save("elevators/batchUpdateDelayTime", params).then(res => {
        this.submitLoadingDelayTime = false;
        this.$emit("save-success");
        this.elevatorList = [];
        this.delayTime = { tiringTime: "80", unUseTime: "600", finishedTime: "1800" };
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingDelayTime = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    saveWb() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      if (!this.wb.maintComId) {
        this.$message.error(this.$l("elevator.selectMaintenance", "请选择维保企业"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingWb = true;
      let params = {
        elevatorIds: elevatorIds,
        maintComId: this.wb.maintComId,
        maintEmpId: this.wb.maintEmpId,
        maintEmpId1: this.wb.maintEmpId1,
      };
      this.$api.save("elevators/batchUpdateWbWorker", params).then(res => {
        this.submitLoadingWb = false;
        //this.$emit("save-success");
        this.elevatorList = [];
        this.wb = {
          maintComId: 0,
          maintComName: "",
          maintEmpId: 0,
          maintEmpName: "",
          maintEmpId1: 0,
          maintEmpName1: "",
        };
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingWb = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    saveWy() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      if (!this.wy.propertyComId) {
        this.$message.error(this.$l("elevator.selectPropertyCom", "请选择物业企业"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingWy = true;
      let params = {
        elevatorIds: elevatorIds,
        propertyComId: this.wy.propertyComId,
        safetyOfficerId: this.wy.safetyOfficerId,
        safetyDirectorId: this.wy.safetyDirectorId,
        safetyMainerId: this.wy.safetyMainerId,
        isAutoReport: this.wy.isAutoReport,
        inspectionTemplateId: this.wy.inspectionTemplateId,
      };
      this.$api.save("elevators/batchUpdateWySafer", params).then(res => {
        this.submitLoadingWy = false;
        //this.$emit("save-success");
        this.elevatorList = [];
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingWy = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    saveAccessPlatform() {
      if (this.elevatorList.length === 0) {
        this.$message.error(this.$l("elevator.selectElevator", "请先选择电梯"));
        return;
      }
      let elevatorIds = [];
      for (let item of this.elevatorList) {
        elevatorIds.push(item.id);
      }
      this.submitLoadingPlatform = true;
      let params = {
        elevatorIds: elevatorIds,
        integrationKey: this.accessPlatform.integrationKey,
      };
      this.$api.save("elevators/batchAccessPlatform", params).then(res => {
        this.submitLoadingPlatform = false;
        this.$emit("save-success");
        this.elevatorList = [];
        this.accessPlatform = {
          integrationKey: "",
        };
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoadingPlatform = false;
        this.$message.error(this.$t("common.tip.saveError") + "," + error);
      });
    },
    onSelectFloorDisplay(row) {
      this.floor.floorDisplayId = row.id;
      this.floor.floorDisplayName = row.name;
      this.getFloorDisplayMapping();
    },
    onSelectFaultTemplate(row) {
      this.faultTemplate.faultTemplateId = row.id;
      this.faultTemplate.faultTemplateName = row.name;
    },
    getFloorDisplayMapping() {
      this.$api.getById("floorDisplayMappings", this.floor.floorDisplayId).then(res => {
        this.elevatorFloors = res.data;
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    onElevatorSelect(row) {
      for (let item of this.elevatorList) {
        if (item.id === row.id) {
          return;
        }
      }
      this.elevatorList.push(row);
    },
    onMultiElevatorSelect(rows) {
      for (let row of rows) {
        let flag = true;
        for (let item of this.elevatorList) {
          if (item.id === row.id) {
            flag = false;
          }
        }
        if (flag) {
          this.elevatorList.push(row);
        }
      }
    },
    removeElevator(row) {
      for (let i = 0; i < this.elevatorList.length; i++) {
        if (this.elevatorList[i].id === row.id) {
          this.elevatorList.splice(i, 1);
        }
      }
    },
    onSelectMaintCom(row) {
      this.wb.maintComId = row.id;
      this.wb.maintComName = row.name;
      this.wb.maintEmpId = 0;
      this.wb.maintEmpName = "";
      this.wb.maintEmpId1 = 0;
      this.wb.maintEmpName1 = "";
    },
    onSelectPropertyCom(row) {
      this.wy.propertyComId = row.id;
      this.wy.propertyComName = row.name;
    },
    onSelectMaintEmp(row) {
      if (this.wb.maintEmpId1 === row.id) {
        this.$message.error(this.$l("elevator.sameMaintenanceWorker", "不能选择同一个维保工!"));
        return;
      }
      this.wb.maintEmpId = row.id;
      this.wb.maintEmpName = row.name;
    },
    onSelectMaintEmp1(row) {
      if (this.wb.maintEmpId === row.id) {
        this.$message.error(this.$l("elevator.sameMaintenanceWorker", "不能选择同一个维保工!"));
        return;
      }
      this.wb.maintEmpId1 = row.id;
      this.wb.maintEmpName1 = row.name;
    },
    selectMaintEmp() {
      if (this.wb.maintComId === 0) {
        this.$message.error(this.$t("elevator.maintComSelectTip"));
        return;
      }
      this.$refs.onSelectMaintEmp.open();
    },
    selectMaintEmp1() {
      if (this.wb.maintComId === 0) {
        this.$message.error(this.$t("elevator.maintComSelectTip"));
        return;
      }
      this.$refs.onSelectMaintEmp1.open();
    },
    maintEmpClear() {
      this.wb.maintEmpId = null;
      this.wb.maintEmpName = "";
    },
    maintEmp1Clear() {
      this.wb.maintEmpId1 = null;
      this.wb.maintEmpName1 = "";
    },
    selectPropertyComEmp() {
      if (this.wy.propertyComId === 0) {
        this.$message.error(this.$t("elevator.propertyComSelectTip"));
        return;
      }
      this.$refs.onSelectPropertyEmp.open();
    },
    selectPropertyComEmp1() {
      if (this.wy.propertyComId === 0) {
        this.$message.error(this.$t("elevator.propertyComSelectTip"));
        return;
      }
      this.$refs.onSelectPropertyEmp1.open();
    },
    selectPropertyComEmp2() {
      if (this.wy.propertyComId === 0) {
        this.$message.error(this.$t("elevator.propertyComSelectTip"));
        return;
      }
      this.$refs.onSelectPropertyEmp2.open();
    },
    onSelectPropertyEmp(row) {
      if (this.wy.safetyOfficerId === row.id) {
        this.$message.error(this.$l("elevator.samePropertySafetyOfficer", "不能选择同一个安全员!"));
        return;
      }
      this.wy.safetyOfficerId = row.id;
      this.wy.safetyOfficerName = row.name;
    },
    onSelectPropertyEmp1(row) {
      if (this.wy.safetyDirectorId === row.id) {
        this.$message.error(this.$l("elevator.samePropertySafetyDirector", "不能选择同一个安全总监!"));
        return;
      }
      this.wy.safetyDirectorId = row.id;
      this.wy.safetyDirectorName = row.name;
    },
    onSelectPropertyEmp2(row) {
      if (this.wy.safetyMainerId === row.id) {
        this.$message.error(this.$l("elevator.samePropertySafetyDirector", "不能选择同一个安全总监!"));
        return;
      }
      this.wy.safetyMainerId = row.id;
      this.wy.safetyMainerName = row.name;
    },
    propertyEmpClear() {
      this.wy.safetyOfficerId = 0;
      this.wy.safetyOfficerName = "";
    },
    propertyEmp1Clear() {
      this.wy.safetyDirectorId = 0;
      this.wy.safetyDirectorName = "";
    },
    propertyEmp2Clear() {
      this.wy.safetyMainerId = 0;
      this.wy.safetyMainerName = "";
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    deleteRow(index) {
      this.elevatorList.splice(index, 1);
    },
    // 选择排查模板
    checkTemplateSelect(row) {
      this.wy.inspectionTemplateName = row.templateName;
      this.wy.inspectionTemplateId = row.id;
    }
  },
};
</script>

<style scoped></style>
