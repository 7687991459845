<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-if="batchAuth" :label="$t('elevator.batchModification')" name="first">
      <batch-modify-elevator></batch-modify-elevator>
    </el-tab-pane>
    <el-tab-pane v-if="faultAuth" :label="$t('elevator.faultTemplate')" name="second">
      <fault-list></fault-list>
    </el-tab-pane>
    <el-tab-pane v-if="floorAuth" :label="$t('elevator.floorDisplayTemplate')" name="third">
      <floor-display></floor-display>
    </el-tab-pane>
    <el-tab-pane v-if="elevatorUserAuth" :label="$t('elevator.callElevator')" name="four">
      <elevator-user-list></elevator-user-list>
    </el-tab-pane>
    <el-tab-pane v-if="otaAuth && showXsd" :label="$t('elevator.ota')" name="five">
      <o-t-a-select-list></o-t-a-select-list>
    </el-tab-pane>
    <el-tab-pane v-if="dnElevatorUserAuth" label="工业上梯" name="six">
      <dn-elevator-user-list></dn-elevator-user-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import FaultList from "@/views/fault/FaultList";
  import FloorDisplay from "@/views/floorDisplay/FloorDisplayList";
  import BatchModifyElevator from "@/views/elevator/elevatorSetting/BatchModifyElevator";
  import ElevatorUserList from "@/views/elevatorUser/ElevatorUserList";
  import OTASelectList from "@/views/elevator/elevatorSetting/OTASelectList";
  import DnElevatorUserList from "@/views/dnElevatorUser/DnElevatorUserList";
  import auth from "@/util/auth";

  export default {
    components: { FaultList, FloorDisplay, BatchModifyElevator, ElevatorUserList, OTASelectList,DnElevatorUserList },
    data() {
      return {
        showXsd: auth.getPlatform() === "xsd",
        batchAuth: this.$auth(109),
        faultAuth: this.$auth(20),
        floorAuth: this.$auth(24),
        elevatorUserAuth: this.$auth(107),
        otaAuth: this.$auth(839),
        dnElevatorUserAuth: this.$auth(843),
        activeName: "first",
      };
    },
    mounted() {
      console.log(auth.getPlatform());
      if (this.batchAuth) {
        this.activeName = "first";
        return;
      }
      if (this.faultAuth) {
        this.activeName = "second";
        return;
      }
      if (this.floorAuth) {
        this.activeName = "third";
        return;
      }
      if (this.elevatorUserAuth) {
        this.activeName = "four";
        return;
      }
      if (this.otaAuth) {
        this.activeName = "five";
        return;
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
